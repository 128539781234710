import React from "react";
import { Box, CSSReset, Flex, Stack, Text } from "@chakra-ui/react";
import { Link } from "gatsby";

const LayOutSample = () => {
  return (
    <>
      <CSSReset />
      <Box>
        <Box backgroundColor={"blue.200"}>ヘッダーです</Box>
        <Flex>
          <Stack direction={"row"}>
            <Box h={"100px"} backgroundColor={"gray.200"} pb={"10px"}>
              <Text>メインコンテンツです</Text>
              <Box textDecoration={"none"} color={"red"}>
                <a href="/">ホームへ戻る</a>
                <br />
                <Link to="/">ホームへ戻る</Link>
              </Box>
            </Box>
            <Box
              display={{ base: "none", md: "inline-flex" }}
              backgroundColor={"orange.200"}
            >
              サイドニュー
            </Box>
          </Stack>
        </Flex>
        <Box backgroundColor={"blue.200"}>フッダーです</Box>
      </Box>
    </>
  );
};
export default LayOutSample;
